import { graphql } from "gatsby";
import React from "react";

import { isBrowser } from "@utils";

// Components
import { DoctorHeroSlider, Doctors } from "@components";

const DoctorsSpecialization = ({ data }) => {
    if (!isBrowser()) {
        return null;
    }
    const params = window.location.pathname.split("/")[2].replace("-", " ");

    const {
        allStrapiDoctor: { nodes: doctors },
    } = data;

    return (
        <>
            {/* <DoctorHeroSlider /> */}
            {doctors && (
                <Doctors
                    title={params}
                    doctors={doctors.filter(
                        ({ specialization }) =>
                            specialization?.name.toLowerCase() === params
                    )}
                />
            )}
        </>
    );
};

export const query = graphql`
    query {
        allStrapiDoctor {
            nodes {
                id
                slug
                name
                bio
                languages
                qualification
                interests
                services {
                    name
                    phone
                    description
                }
                specialization {
                    name
                }
                image {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: DOMINANT_COLOR
                                formats: [AUTO, WEBP, AVIF]
                            )
                            fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    url
                }
                sample
            }
        }
    }
`;

export default DoctorsSpecialization;
